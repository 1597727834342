import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLayoout from "../components/layout-page"
import InstaCard from "../components/Instagram/InstaPostContainer"


const PostContainer = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-around;
   padding: 1rem 0 1rem 0;
`


const InstagramPage = ({ data }) => {
   const posts = (data.allInstaNode.edges).sort((a,b) => a.timestamp - b.timestamp)
   return (
      <Layout>
         <SEO title="Instagram"/>
         <PageLayoout title="Instagram">
            <PostContainer>
               {
                  posts.map(({node:post}) => (
                     <InstaCard key={post.id} post={post} />
                  ))
               }
            </PostContainer>
         </PageLayoout>
      </Layout>
   )
}

export default InstagramPage;

export const pageQuery = graphql`
   query {
      allInstaNode(sort: { order: DESC, fields: timestamp }) {
         edges {
         node {
            id
            caption
            timestamp
            mediaType
            localFile {
               childImageSharp {
               fixed(width: 320) {
                  ...GatsbyImageSharpFixed
               }
               }
            }
         }
         }
      }
   }
`