import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"


import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"

import ImageContainer from "../styles/ImageContainer"

const StyledCard = styled(Card)`
   width: 320px;
   /* width: ${props => props.theme.cardMaxWidth}; */
   margin: 25px;
   padding: 0px;
   display: flex;
   flex-direction: column;
   align-content: center;
   justify-content: start;
`


const StyledContent = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 16px;
`

const getDate = (timestamp) => {
  var a = new Date(timestamp * 1000)
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var time = `${month} ${date}, ${year}`
  return time
}

const InstaCard = ({post}) => {
   const data = useStaticQuery(graphql`
      query {
         playBtn: file(
            relativePath: { eq: "white-play-btn.png" }
         ){
            childImageSharp {
               fixed(width: 30) {
                  ...GatsbyImageSharpFixed
               }
            }
         }
      }
   `)
   return (
     <StyledCard>
       <CardActionArea>
         <a
           href={`https://instagram.com/p/${post.id}`}
           target="_blank"
           rel="noopener noreferrer"
           style={{ textDecoration: "none", color: "inherit" }}
         >
           <ImageContainer>
              <Img fixed={post.localFile.childImageSharp.fixed} />
             {
               post.mediaType === "GraphVideo" &&
               <Img
                  fixed={data.playBtn.childImageSharp.fixed }
                  style={{position:'absolute' , top: '16px', right: '16px', opacity:'0.8'}}
               />
            }
           </ImageContainer>
           <StyledContent>
             <Typography variant="body2" gutterBottom>
               {post.caption}
             </Typography>
             <br />
             <Typography variant="caption">
               {getDate(post.timestamp)}
             </Typography>
           </StyledContent>
         </a>
       </CardActionArea>
     </StyledCard>
   )
}


export default InstaCard;
